<template>
  <div class="abnormal">
    <!-- <NavBar :list="navList" name="历史退款记录"></NavBar> -->
    <Nvalid :list="navList"></Nvalid>
    <div class="public-box refundhis">
      <el-form :inline="true">
        <el-form-item>
          <el-date-picker v-model="date" @change="dateChange" format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <MyInput v-model="formInline.worker_name" placeholder="输入退款商户">
            <template slot="pre">退款提交人:</template>
          </MyInput>
        </el-form-item>

        <el-form-item>
          <MyButton title="搜索" @click="currentChange(1)">
            <template slot="preImage">
              <img src="../../../assets/img/icon/search.png" alt="" />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton @click="exported" :accessId="35184">
            <span>导出</span>
            <template slot="preImage">
              <img src="../../../unit/img/down.png" alt />
            </template>
          </MyButton>
        </el-form-item>
      </el-form>
      <el-table :data="tableData" v-loading="loading" :header-cell-style="{
        'text-align': 'center',
        background: 'rgb(245, 245, 245)',
      }" :cell-style="{ 'text-align': 'center' }">
        <el-table-column label="退款提交时间" prop="create_at"></el-table-column>
        <el-table-column label="退款提交人" prop="worker_name"></el-table-column>
        <el-table-column label="退款金额" prop="refund_money"></el-table-column>
        <el-table-column label="退款账号" prop="refund_number"></el-table-column>
        <el-table-column label="账号户名" prop="refund_name"></el-table-column>
        <el-table-column label="状态" prop="status">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">待审核</span>
            <span v-if="scope.row.status == 10">待打款</span>
            <span v-if="scope.row.status == 20">已完成</span>
            <span v-if="scope.row.status == 30">已驳回</span>
          </template>
        </el-table-column>
        <el-table-column label="退款备注" prop="refund_remarks"></el-table-column>
        <el-table-column label="驳回原因" prop="refund_desc"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="slot-box">
              <pop tips="处理" :accessId="35185" @myclick="dealWith(scope.row)" v-if="scope.row.status == 0">
                <img class="icon" src="../../../assets/img/icon/xiangqing.png" alt />
              </pop>
              <pop tips="处理" :accessId="35185" v-if="scope.row.status == 10" @myclick="fileVoucher(scope.row)">
                <img class="icon" src="../../../assets/img/icon/xiangqing.png" alt />
              </pop>
              <pop tips="查看凭证" :accessId="35186" @myclick="checkVoucher(scope.row)" v-if="scope.row.status == 20">
                <img class="icon" src="../../../assets/img/icon/chakan.png" alt />
              </pop>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="apiPage">
        <el-pagination next-text="下一页" prev-text="上一页" layout="total, prev, pager, next" :total="total"
          :page-size="formInline.pageSize" :current-page="formInline.pageNum"
          @current-change="currentChange"></el-pagination>
      </div>
    </div>
    <el-dialog title="设置" :visible.sync="dialogVisible" width="880px" @close="handleClose" :close-on-click-modal="false">
      <el-form :model="ruleForm" ref="ruleForm" label-width="200px" class="demo-ruleForm">
        <el-form-item label="一次能处理几条退款申请：">
          <el-col :span="14">
            <el-input v-model="ruleForm.values" class="public-input" placeholder="请输入一次能处理几条退款申请"></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <MyButton title="取消" @click="dialogVisible = false"></MyButton>
        <MyButton title="确认" left @click="setRefund"></MyButton>
      </span>
    </el-dialog>
    <el-dialog title="上传凭证" :visible.sync="voucherVisible" width="880px" @close="close" :close-on-click-modal="false">
      <el-form ref="ruleForm" label-width="200px" class="demo-ruleForm">
        <el-form-item label="退款凭证:">
          <el-col :span="14">
            <el-upload class="upload-demo" ref="upload" action :show-file-list="false" :on-change="handlePreview"
              :auto-upload="false">
              <img :src="voucherParams.refund_image" class="imgBox" alt v-if="voucherParams.refund_image" />
              <el-button icon="el-icon-upload2" type="primary">上传</el-button>
            </el-upload>
          </el-col>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <MyButton title="驳回" @click="reject"></MyButton>
        <MyButton title="确认" left @click="confirm"></MyButton>
      </span>
    </el-dialog>
    <el-dialog title="申请驳回" :visible.sync="rejectVisible" width="880px" @close="rejectClose"
      :close-on-click-modal="false">
      <el-form ref="ruleForm" label-width="200px" class="demo-ruleForm">
        <el-form-item label="驳回原因:">
          <el-input v-model="rejectForm.refund_desc" class="public-input" placeholder="请输入驳回原因"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <MyButton title="取消" @click="rejectVisible = false"></MyButton>
        <MyButton title="确认" left @click="rejectConfirm"></MyButton>
      </span>
    </el-dialog>
    <el-dialog title="查看凭证" :visible.sync="examineVisible" width="880px" @close="examineClose"
      :close-on-click-modal="false">
      <div class="examineBox">
        <img :src="refundImage" alt />
      </div>
      <span slot="footer" class="dialog-footer">
        <MyButton title="关闭" @click="examineVisible = false"></MyButton>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Nvalid from "../../../unit/components/nvalid.vue";
export default {
  name: "refund",
  data () {
    return {
      formInline: {
        pageSize: 10,
        pageNum: 1,
        merchants_stall_name: "",
        status: 2,
        beginTime: "",
        endTime: "",
      },
      total: 0,
      navList: [
        { title: "退款管理", url: "/finance/refund" },
        { title: "历史退款记录" },
      ],
      tableData: [],
      dialogVisible: false,
      ruleForm: {
        key: "refundRentSetting",
        describe: "退款最多可以处理多少条",
        values: 0,
      },
      options: [],
      voucherVisible: false,
      voucherParams: {
        refund_image: "",
        bill_refund_id: "",
      },
      rejectVisible: false,
      rejectForm: {
        refund_desc: "",
      },
      examineVisible: false,
      refundImage: "",
      date: [],
      loading: false,
    };
  },
  created () {
    this.getList();
  },
  methods: {
    dateChange (date) {
      if (date) {
        this.formInline.beginTime = date[0];
        this.formInline.endTime = date[1];
      } else {
        this.formInline.beginTime = "";
        this.formInline.endTime = "";
      }
    },
    rejectClose () {
      this.rejectForm.refund_desc = "";
    },
    examineClose () {
      this.refundImage = "";
    },
    checkVoucher (row) {
      this.$request.HttpGet("/bill_refund/edit", {
        id: row.bill_refund_id,
      }).then((res) => {
        this.refundImage = res.data.refund_image;
        if (!this.refundImage)
          return this.$common.notifyError("没有查询到凭证");
        this.examineVisible = true;
      });
    },
    fileVoucher (row) {
      this.voucherParams.bill_refund_id = row.bill_refund_id;
      this.voucherVisible = true;
    },
    close () {
      this.voucherParams = {
        refund_image: "",
        bill_refund_id: "",
      };
    },
    rejectConfirm () {
      this.$request
        .HttpPost("/bill_refund/edit", {
          refund_desc: this.rejectForm.refund_desc,
          status: 30,
          bill_refund_id: this.voucherParams.bill_refund_id,
        })
        .then((res) => {
          this.$common.notifySuccess(res.msg);
          this.getList();
          this.rejectVisible = false;
          this.voucherVisible = false;
        });
    },
    reject () {
      this.rejectVisible = true;
    },
    confirm () {
      this.$request
        .HttpPost("/bill_refund/edit", {
          refund_image: this.voucherParams.refund_image,
          bill_refund_id: this.voucherParams.bill_refund_id,
        })
        .then((res) => {
          this.$common.notifySuccess(res.msg);
          this.getList();
          this.voucherVisible = false;
        });
    },
    handlePreview (file) {
      if (file && file.raw) {
        let formData = new FormData();
        formData.append("file", file.raw);
        this.$request.HttpPost("/system/upload/upImg", formData).then((res) => {
          this.voucherParams.refund_image =
            process.env.VUE_APP_IMG_URL + res.data.fileInfo.hostUrl;
        });
      }
    },
    dealWith (row) {
      this.$myconfirm("是否办理当前退款申请?", "提示").then(() => {
        this.$request
          .HttpPost("/bill_refund/edit", {
            status: 10,
            bill_refund_id: row.bill_refund_id,
          })
          .then((res) => {
            this.$common.notifySuccess(res.msg);
            this.getList();
          });
      });
    },
    getList () {
      this.$request
        .HttpGet("/bill_refund/list", this.formInline)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.list || [];
          this.total = res.data.total;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    currentChange (page) {
      this.formInline.pageNum = page;
      this.getList();
    },
    handleClose () {
      this.ruleForm.values = 0;
    },
    setRefund () {
      this.$request.HttpPost("/setting/add", this.ruleForm).then((res) => {
        this.$common.notifySuccess(res.msg);
        this.dialogVisible = false;
      });
    },
    getRefundRentSetting () {
      this.$request
        .HttpGet("/setting/edit", {
          key: "refundRentSetting",
        })
        .then((res) => {
          res.data.values = res.data.value;
          this.ruleForm = res.data;
          this.dialogVisible = true;
        });
    },
    gohis () {
      this.$router.push("/finance/refund/refundhis");
    },
    exported () {
      this.$exported(
        "/property/bill_refund/export",
        this.formInline,
        "历史退款记录.xlsx"
      );
    },
  },
  components: { Nvalid },
};
</script>
<style lang="scss" scoped>
.refundhis {
  margin-top: 20px;
}

.autoFlex {
  display: flex;
  justify-content: space-between;

  .right {
    display: flex;
    align-items: center;

    .totalAmout {
      margin-right: 15px;
    }
  }
}

.imgBox {
  width: 500px;
  margin-bottom: 10px;
}

.examineBox {
  width: 100%;

  img {
    width: 100%;
    max-height: 550px;
  }
}
</style>
